import VuexModuleGenerator from './../../libraries/vuex_module_generator';
import structure from '@/assets/structure.json';
import orderables from '@/assets/orderables.json';

export default VuexModuleGenerator.generate(
{
	namespaced: true,
	state:
	{
		// current environment for presenter (set from the $wrapper plugin in App.vue)
		environment: null,
		
		// all files that can be ordered
		orderables: orderables,
		
		// content object structure
		structure: structure,
		
		// parent object of current object
		parent: null,
		
		// current object
		object: null
	}
});