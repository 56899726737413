import LocalStorage from '@/libraries/global/localstorage.js';

// the key where the order is saved in localStorage
const localStorageKey = 'order';
	
// the current order
let order = new Set(LocalStorage.get(localStorageKey)) ?? [];

// add an orderable to the current order
const add = function (id)
{
	order.add(id);
	
	LocalStorage.set(localStorageKey, [...order]);
};

// remove an orderable from the current order
const remove = function (id)
{
	order.delete(id);
	
	LocalStorage.set(localStorageKey, [...order]);
};

// reset the current order
const clear = function ()
{
	order = new Set();
	
	LocalStorage.set(localStorageKey, [...order]);
};

export default
{
	order,
	add,
	remove,
	clear
};