<template>
	<div>
		<v-breadcrumbs
			class="px-4"
			large
			:items="structurePathItemNames"
		>
			<template
				#divider
			>
				<v-icon>
					mdi-chevron-right
				</v-icon>
			</template>
			<template v-slot:item="{ item }">
				<v-breadcrumbs-item>
					<a
						class="v-breadcrumbs__item"
						:href="item.href"
						v-html="item.text"
					></a>
				</v-breadcrumbs-item>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<script>
import TranslationHelper from '@/libraries/translationhelper.js';
import NavigationHelper from '@/libraries/navigationhelper.js';

export default
{
	name: 'Breadcrumbs',
	
	props:
	{
		itemNames:
		{
			type: Array,
			default: []
		}
	},
	
	methods:
	{
		replaceAllStrings (string, map)
		{
			return string.replace(
				new RegExp(Object.keys(map).join('|'), 'gi'),
				match => map[match.toLowerCase()]
			);
		}
	},
	
	computed:
	{
		structurePathItemNames ()
		{
			// keep a reference to current object and parent while going through all indexes
			let object = this.$store.state.presenter.structure;
			let parent = this.$store.state.presenter.structure;
			
			// need this so that the computed property updates when the route query 'indexes' updates
			this.$route.query?.indexes;
			const indexes = NavigationHelper.getParsedIndexesQuery();
			
			const names = [];
			
			// add a -1 index to the beginning to process the global root object itself
			for (const [counter, index] of Object.entries([-1, ...indexes]))
			{
				if (index != -1)
				{
					object = parent.objects[index];
					parent = object;
				}
				
				let text;
				
				for (const field of this.itemNames)
				{
					const translated = TranslationHelper.translateWithBaseObjectValueAsDefault(object, field);
					
					if (translated !== undefined)
					{
						text = translated;
						
						break;
					}
				}
				
				text = this.replaceAllStrings(
					text,
					{
						'<br>': ' ',
						'<br/>': ' ',
						// '<sup>': '',
						// '</sup>': '',
					}
				);
				
				names.push(
					{
						text: text,
						disabled: false,
						href: window.location.origin + window.location.pathname + `#/${object.type}?indexes=${JSON.stringify(indexes.slice(0, counter))}`
					}
				);
			}
			
			return names;
		}
	},
	
	data: () =>
	(
		{
			
		}
	)
};
</script>

<style lang="scss">

</style>