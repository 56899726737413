/**
 * @file Collection of vue router specific helper functions for initialization. This functionality must be split with vue_router_helper.js because a circular import logic is not possible.
 * @author Josua Todebusch
 * @version 2.0.1
 */

import i18n from '@/i18n';
import Utility from '@/libraries/global/utility.js';

export default
{
	/**
	 * @description Generates all static pages by looping dynamically over the 'pages' directory with webpack. The path to the component relative from the pages directory will be set as the route path whereas all other information is assumed from the component name (title, name).
	 * 
	 * @param {require.context} context Return value of webpack require.context statement
	 * @param {string[]} excludes Skips generic initialization for routes matching this path inside the 'pages' directory (e.g. 'Login.vue' ignores the Login component directly in the root of pages)
	 * 
	 * @returns {object[]}
	 */
	generateStaticRoutes: function (context, excludes = [])
	{
		let pages = [];
		
		context.keys().forEach( (fileName) =>
		{
			if (excludes.some(exclude => fileName.endsWith(exclude)))
			{
				return;
			}
			
			const path = Utility.string.removeSuffix(
				Utility.string.removePrefix(fileName, './'),
				'.vue'
			);
			
			const name = path.split('/').pop();
			
			pages.push(
				{
					path: '/' + path.toLowerCase(),
					name: name,
					// include single component file from loaded context
					component: () => context(fileName),
					meta:
					{
						title: this.buildTitle(
							i18n.te('components.Navigation.titles.' + path.toLowerCase()) ?
								i18n.t('components.Navigation.titles.' + path.toLowerCase()) :
								name
						)
					}
				}
			);
		});
		
		return pages;
	},
	
	/**
	 * @description Builds a string to use as Route title, joining all given strings with the translated appName
	 * 
	 * @param {...string} strings Any amount of strings to join for title
	 * 
	 * @returns {string}
	 */
	buildTitle ()
	{
		return [i18n.t('appName'), ...arguments].join(' - ');
	}
};