import VuexModuleGenerator from './../../libraries/vuex_module_generator';

export default VuexModuleGenerator.generate(
{
	namespaced: true,
	state:
	{
		visible: false,
		text: '',
		color: '',
		icon: ''
	}
});