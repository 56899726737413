/**
 * @file Collection of helper functions for translation management in the presenter framework
 * @author Josua Todebusch
 * @version 1.1.1
 */

import Store from '@/store/index.js';
import I18n from '@/i18n.js';
import Utility from './global/utility';

const self =
{
	/**
	 * Set object translation data into I18n messages to make them accessible as 'object' sub field (e.g. property 'name' of current object accessible as 'object.name')
	 */
	initializeI18nWithObjectTranslationData ()
	{
		const objectLocaleField = 'locale';
		
		for (const locale in Store.state.presenter.object[objectLocaleField])
		{
			// set messages for i18n
			I18n.setLocaleMessage(
				locale,
				Object.assign(
					// existing messages (if exists for this locale)
					I18n.messages?.[locale] ?? {},
					// 'object' subobject representing locale for current structure object
					{
						object: Store.state.presenter.object[objectLocaleField]?.[locale]
					}
				)
			);
		}
	},
	
	/**
	 * Translates a value from an object based on the fields (basically same as $t, but with data that is provided as parameter)
	 * Procedure: Looks for field "locale", then a field representing current selected locale. Uses fallbackLocale of I18n instead if locale does not exist in object
	 * 
	 * @param {object} object object to translate values from
	 * @param {string} value i18n translation string (e.g. 'object.name')
	 * 
	 * @returns string
	 */
	translateObjectValue (object, value)
	{
		if ([null, undefined, true, false].includes(value))
		{
			console.error('Cannot translate without a valid value!', ...arguments);
			
			return value;
		}
		
		const translation = Utility.object.resolveValue(
			object,
			[
				'locale',
				I18n.locale ?? I18n.fallbackLocale,
				...value?.split('.')
			]
		);
		
		if (translation)
		{
			return translation;
		}
		
		return Utility.object.resolveValue(
			object,
			[
				'locale',
				I18n.fallbackLocale,
				...value?.split('.')
			]
		) ?? value;
	},
	
	/**
	 * Translate a value normally but use the base object value as backup value:
	 * {
	 * 		path: "pdfs/pdf.pdf",
	 * 		locale:
	 * 		{
	 * 			en:
	 * 			{
	 * 				path: "pdfs/pdf_en.pdf"
	 * 			}
	 * 		}
	 * }
	 * 
	 * @param {object} object structure object
	 * @param {string} value i18n translation string (e.g. 'path')
	 * @returns 
	 */
	translateWithBaseObjectValueAsDefault (object, value)
	{
		const translatedValue = self.translateObjectValue(object, value);
		
		return translatedValue !== value ? translatedValue : object[value];
	}
};

export default self;