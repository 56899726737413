import VuexModuleGenerator from './../../libraries/vuex_module_generator';

export default VuexModuleGenerator.generate(
{
	namespaced: true,
	state:
	{
		url: 'http://localhost/__dev/vuetify/components/administration template/server/request.php',
		fileUrl: 'http://localhost/__dev/vuetify/components/administration template/server/'
	}
});