import VuexModuleGenerator from './../../libraries/vuex_module_generator.js';

export default VuexModuleGenerator.generate(
{
	namespaced: true,
	state:
	{
		languages:
		[
			{
				// name string
				label: 'English',
				// code is used for i18n module
				code: 'en',
				// icon (optional) is used for flag-icons npm package (code is used if not set)
				icon: 'gb'
			},
			{
				label: 'Deutsch',
				code: 'de'
			},
		]
	}
});