/**
 * @file Module for keeping track of requests and catching double requests (fetch based)
 * @author Josua Todebusch
 * @version 1.4.0
 */

const RequestManager =
{
	// default server url
	url: '',
	
	// function for automatically building a key from request data
	keyBuilder: (request) =>
	{
		return [request.options.body.name, request.options.body.action].filter(item => !!item).join('/') || request.url;
	},
	
	// request body processing options
	processes:
	{
		JSON: value => JSON.stringify(value),
		FormData: value => Utility.object.toFormData(value)
	},
	
	// list of requests that were denied of execution
	denied: [],
	
	// queue of requests that should be made active automatically when a similar request is finished
	queued: [],
	
	// reference of currently active requests
	active: {},
	
	// list of finished requests
	finished: [],
	
	log: false,
	
	/**
	 * @typedef Request
	 * @type {object}
	 * @property {url=} url					the url to fetch from
	 * @property {string=} key				the key for queueing/denying double requests
	 * @property {string=} queue			if the request should be queued if key is active
	 * @property {date=} registered			timestamp of first try call
	 * @property {date=} initiated			timestamp of setting active
	 * @property {date=} finished			timestamp of finished
	 * @property {object} options			fetch init object (see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch)
	 */
	
	/**
	 * @description Initiation method
	 * 
	 * @param {Request} request				request object as described above
	 * 
	 * @return Promise
	 */
	try: function (request)
	{
		// set register time
		if (!request.registered)
		{
			request.registered = Date.now();
		}
		
		// build a key if not given
		if (!request.key)
		{
			request.key = this.keyBuilder(request);
		}
		
		// make the default request type POST
		if (!request.options.method)
		{
			request.options.method = 'POST';
		}
		
		// if not a recall of this function by queue functionality, create promise to return and store resolve and reject for later use
		if (!request.promise)
		{
			request.promise = new Promise(function (resolve, reject)
			{
				request.resolve = resolve;
				request.reject = reject;
			});
		}
		
		// if a request with the same key is activve
		if (this.active[request.key])
		{
			// if the request should be queued: queue but return promise anyway
			if (request.queue)
			{
				this.queued.push(request);
				
				return request.promise;
			}
			
			this.denied.push(request);
			
			return new Promise(() => {});
		}
		
		// set request
		this.active[request.key] = request;
		
		// if no processing is explicitly set
		if (!request.process)
		{
			// detect Blob/File and set FormData processing
			if (Object.values(Utility.object.flat(request.options.body)).some(value => value instanceof Blob || value instanceof File))
			{
				request.process = 'FormData';
			}
			// use JSON as standard processing
			else
			{
				request.process = 'JSON';
			}
		}
		
		// process request body data
		if (this.processes[request.process])
		{
			request.options.body = this.processes[request.process](request.options.body);
		}
		
		// set initiation time
		request.initiated = Date.now();
		
		// set url to request object if only general url is provided
		if (!request.url)
		{
			request.url = this.url;
		}
		
		// fetch from url with options
		fetch(request.url, request.options).then(async (response) =>
		{
			// start building result object
			request.result =
			{
				request: Object.fromEntries
				(
					Object.entries(request).filter(([key, value]) => !['promise', 'reject', 'resolve'].includes(key))
				),
				response: response
			};
			
			if (response.status !== 200)
			{
				request.reject(request.result);
			}
			
			// get text and parse it (store both)
			request.result.text = await response.text();
			
			if (this.log)
			{
				console.log(request.result.text);
			}
			
			try
			{
				request.result.data = JSON.parse(request.result.text);
			}
			catch
			{
				request.result.data = null;
			}
			
			// set finished time
			request.finished = Date.now();
			
			// if successfully parsed and server gave a sucess
			if (request.result?.data?.success)
			{
				request.resolve(request.result);
			}
			else
			{
				request.reject(request.result);
			}
		}).catch((error) =>
		{
			// reject if some error occurred
			request.reject(error || request.result);
		}).finally(() =>
		{
			// move to finished and remove the request from active
			this.finished.push(this.active[request.key]);
			
			delete this.active[request.key];
			
			// queue the next one with the same key if one exists
			const index = this.queued.findIndex(queued => queued.key === request.key);
			
			if (~index)
			{
				const newRequest = this.queued[index];
				
				this.queued = this.queued.filter((queued, key) => key !== index);
				
				// initiate queued request
				this.try(newRequest);
			}
		});
		
		return request.promise;
	}
};

if (window)
{
	window.RequestManager = RequestManager;
}

export default RequestManager;