/**
 * @file Generates a standard mutation and standard getter and returns a modified Vuex Store object
 * @author Josua Todebusch
 */
export default
{
	/**
	 * @description Generates a standard getter and setter for a Vuex Store object
	 * @param {object} vuexModuleConfiguration
	 * @returns {object}
	 */
	generate: function (vuexModuleConfiguration)
	{
		if (!vuexModuleConfiguration.mutations)
		{
			vuexModuleConfiguration.mutations = {};
		}
		
		vuexModuleConfiguration.mutations.set = function (state, data = [])
		{
			if (typeof data[0] !== 'object')
			{
				data = [data];
			}
			
			if (!data.length)
			{
				return;
			}
			
			if (Array.isArray(data[0]))
			{
				data = Object.fromEntries(Object.keys(state).map((element, index) =>
				{
					return [element, data[0][index]];
				}).filter(element => element[1] !== undefined));
			}
			else
			{
				data = data[0];
			}
			
			for (const [key, value] of Object.entries(data))
			{
				state[key] = value;
			}
		};
		
		if (!vuexModuleConfiguration.getters)
		{
			vuexModuleConfiguration.getters = {};
		}
		
		vuexModuleConfiguration.getters.get = function (state)
		{
			return state;
		};
		
		return vuexModuleConfiguration;
	}
};