/**
 * @file Module to deal with localStorage. Also saves the datatypes of given objects so that
 * they can be retrieved and automatically parsed back to their format. This module itself 
 * checks if localStorage is supported in the current environment and will set itself to null
 * if it is not.
 * @author Josua Todebusch
 * @version 2.0.0
 */
export default (function ()
{
	// validation code:  check if local storage is supported in this environment
	if (!window || !window.localStorage)
	{
		return null
	}
	
	// the key that this module uses to save all the data types for the submitted values
	const internalDataTypeStorageKey = 'LOCAL_STORAGE_TYPE_REGISTRY';
	
	/**
	 * @description internal function to get a type registry value
	 * @param {string} key
	 * @returns {sting}
	 */
	const getRegistryValue = function (key)
	{
		// load registry object
		let dataTypes = JSON.parse(localStorage.getItem(internalDataTypeStorageKey)) || {};
		
		return dataTypes[key];
	};
	
	/**
	 * @description internal function to update a type registry value
	 * @param {string} key
	 */
	const updateRegistry = function (key, type)
	{
		// load registry object
		let dataTypes = JSON.parse(localStorage.getItem(internalDataTypeStorageKey)) || {};
		
		dataTypes[key] = type;
		
		localStorage.setItem(internalDataTypeStorageKey, JSON.stringify(dataTypes));
	};
	
	/**
	 * @description internal function to delete a type registry value
	 * @param {string} key
	 */
	const deleteRegistryValue = function (key)
	{
		// remove the corresponding field from the data type registry
		let dataTypes = JSON.parse(localStorage.getItem(internalDataTypeStorageKey)) || {};
		
		delete dataTypes[key];
		
		localStorage.setItem(internalDataTypeStorageKey, JSON.stringify(dataTypes));
	};
	
	// public functions
	return {
		/**
		 * @description set a local storage value at key. returns true if succeeded or null on failure (maximum local storage value of 5mb reached)
		 * 
		 * @param {string} key
		 * @param {object|array|string|number|boolean} value
		 */
		set: function (key, value)
		{
			let type = typeof value;
			
			if (type === 'object')
			{
				value = JSON.stringify(value);
			}
			
			try
			{
				// set the value
				localStorage.setItem(key, value);
				
				// update the data type registry
				updateRegistry(key, type);
			}
			catch (e)
			{
				return null;
			}
			
			return true;
		},
		
		/**
		 * @description get a local storage value from key. returns true if succeeded or null on failure (value does not exist)
		 * 
		 * @param {string} key
		 * 
		 * @returns {object|array|string|number|boolean}
		 */
		get: function (key)
		{
			let dataType = getRegistryValue(key);
			
			if (!dataType)
			{
				return null;
			}
			
			let item = localStorage.getItem(key);
			
			// parse the stored value back to its original type
			if (dataType === 'object')
			{
				item = JSON.parse(item);
			}
			else if (dataType === 'function')
			{
				item = eval(`(${item})`);
			}
			else if (dataType === 'boolean')
			{
				item = (item === 'true');
			}
			else if (dataType === 'number')
			{
				item = Number.parseFloat(item);
			}
			else if (dataType === 'undefined')
			{
				item = undefined;
			}
			
			return item;
		},
		
		/**
		 * @description remove a local storage value from key and return it
		 * @param {string} key
		 * @returns {object|array|string|number|boolean}
		 */
		remove: function (key)
		{
			let item = this.get(key);
			
			deleteRegistryValue(key);
			
			localStorage.removeItem(key);
			
			return item;
		},
	};
})();