import Store from '@/store/index.js';
import Utility from '@/libraries/global/utility.js';

const globalModules = [];

require.context('@/libraries/global', false, /\.js$/).keys().forEach(
	(fileName) =>
	{
		globalModules.push(Utility.string.removePrefix(fileName, './'));
	}
);

// override some names
const names =
{
	'localstorage.js': 'LocalStorage',
	'requestmanager.js': 'RequestManager'
};

export default
{
	install (Vue, options)
	{
		Vue.prototype.$globals = {};
		
		globalModules.forEach(
			(module) =>
			{
				const name = names[module] ?? Utility.string.capitalize(Utility.string.removeSuffix(module, '.js'));
				
				Vue.prototype.$globals[name] = require('@/libraries/global/' + module).default;
			}
		);
		
		if (Vue.prototype.$globals.RequestManager)
		{
			Vue.prototype.$globals.RequestManager.url = Store.state.server.url;
			Vue.prototype.$globals.RequestManager.log = true;
		}
	}
}