import Vue from 'vue';
import Vuex from 'vuex';
import Utility from './../libraries/global/utility';

Vue.use(Vuex);

export default new Vuex.Store(
	{
		modules: (function ()
		{
			const modulesContext = require.context('./modules', false, /\.js$/);
			
			let modules = {};
			
			modulesContext.keys().filter(module => module !== 'index.js').forEach( (fileName) =>
			{
				const moduleName = Utility.string.removeSuffix(fileName.split('/').pop(), '.js');
				
				modules[moduleName] = modulesContext(fileName).default;
			});
			
			return modules;
		})()
	}
);