import { render, staticRenderFns } from "./GbButton.vue?vue&type=template&id=74d18528&scoped=true"
import script from "./GbButton.vue?vue&type=script&lang=js"
export * from "./GbButton.vue?vue&type=script&lang=js"
import style0 from "./GbButton.vue?vue&type=style&index=0&id=74d18528&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d18528",
  null
  
)

export default component.exports