import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index.js';
import Presenter from '@/libraries/presenter.js';
import TranslationHelper from '@/libraries/translationhelper.js';
import VueRouterInitializationHelper from '@/libraries/vue_router_initialization_helper.js';

Vue.use(VueRouter);

const router = new VueRouter(
	{
		//mode: 'history',
		routes:
		[
			// reroute not existing pages
			{
				path: '*',
				redirect: '/'
			},
			// starting page
			{
				path: '/',
				redirect: '/screensaver'
			},
			// generically create a route for every static page
			...VueRouterInitializationHelper.generateStaticRoutes(
				require.context(`@/pages`, true, /\.vue$/, 'lazy')
			),
			// generically create a route for every template
			...VueRouterInitializationHelper.generateStaticRoutes(
				require.context(`@/templates`, true, /\.vue$/, 'lazy')
			),
		]
	}
);

// close presenter web view when going to another route
router.beforeEach((to, from, next) =>
{
	if (router.app.$wrapper.type() === 'Presenter')
	{
		router.app.$wrapper.hideWebView();
	}

	return next();
});

router.afterEach((to, from) =>
{
	// make indexes always be set to empty array
	if (!to.query.indexes)
	{
		to.query.indexes = [];
	}
	
	// parse the field as JSON
	if (typeof to.query.indexes === 'string')
	{
		to.query.indexes = JSON.parse(to.query.indexes);
	}
	
	// select current content object
	Presenter.evaluateCurrentStructureLocation(to.query.indexes);
	
	// encode as JSON again
	to.query.indexes = JSON.stringify(to.query.indexes);
	
	// set object translation data with file in locale directory
	TranslationHelper.initializeI18nWithObjectTranslationData();
	
	// Use next tick to handle router history correctly
	// see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
	Vue.nextTick(() =>
	{
		// set the title of the route or the standard title
		document.title = to.meta.title;
	});
	
	if (router.app.$wrapper.type() === 'Presenter')
	{
		if (Store.state.presenter.object.type)
		{
			const objectTypeStatisticProperties =
			{
				home: 'name',
				overview: 'name',
				website: 'url',
				pdf: 'path',
				image: 'path',
				video: 'path',
				presentation: 'path'
			};
			
			if (!objectTypeStatisticProperties[Store.state.presenter.object.type])
			{
				console.error('Could not write a statistic entry for content object!', Store.state.presenter.object);
				
				return;
			}
			
			// use this method for now
			router.app.$wrapper.addVideoTitleForStats(
				TranslationHelper.translateWithBaseObjectValueAsDefault(
					Store.state.presenter.object,
					objectTypeStatisticProperties[Store.state.presenter.object.type]
				)
			);
		}
		// tracking for static pages (e.g. cart, screensaver)
		else
		{
			
		}
	}
});

export default router;